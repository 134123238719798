<template>
  <v-card class="accounting-document d-flex flex-column" style="height: 100%;">
    <v-toolbar color="grey darken-3" dark elevation="1" style="flex: 0" height="52">
      <v-toolbar-title>
        {{ `${title} ${isNew ? '(Створення)' : '(Редагування)'}` }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-navigation-drawer
        right
        app
        width="400"
        v-model="document_state.show"
        style="z-index: 9999"
        color="grey lighten-4"
    >
      <v-card tile flat class="transparent">
        <v-card-text class="pa-0">
          <div style="display: flex; align-items: center" class="py-2 pl-3 pr-1 grey darken-2 white--text">
            <div style="flex: 1; font-weight: 500;">Перелік помилок заповнення</div>
            <div style="flex: 0 0 30px">
              <v-btn icon small @click.stop="document_state.show = false" class=" grey darken-2">
                <v-icon color="secondary">mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="px-2 pt-2 pb-2">
          <div>
            <div style="display: flex; margin-bottom: 5px; padding: 3px; border-radius: 5px;"
                 class="elevation-3 white"
                 v-for="(item, item_idx) in document_state.errors"
                 :key="`error-${item_idx}`"
            >
              <div class="py-1 px-2">
                <div class="mb-1">
                  <v-chip small color="success" label class="mr-1" style="height: 20px !important;">
                    {{ item.table }}
                  </v-chip>
                  <v-chip small color="grey lighten-3" label class="mr-1" style="height: 20px !important;"
                          v-if="item.row_num">
                    {{ `№ рядка: ${item.row_num}` }}
                  </v-chip>
                </div>
                <div style="color: #2d2d2d; font-weight: 400; font-size: .76rem">
                  {{ item.text }}
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
    <v-card-text class="pt-3 pb-1" style="flex: 1; display: flex; flex-direction: column; overflow: hidden;">
      <div class="conducted-box d-flex">
        <v-alert
            dense
            text
            class="mb-0 py-1"
            :color="conducted ? 'success' : 'secondary'"
            :type="conducted ? 'success' : 'warning'"
            style="flex: 1"
        >
          {{ conducted ? 'Документ проведений' : 'Документ не проведений' }}
        </v-alert>
      </div>
      <div class="document-header" style="flex: 0">
        <div class="document-header-row pt-2 pb-1">
          <div style="flex: 0 0 180px; margin-right: 16px">
            <date_input v-model="date" label="Дата: " :time="true" @userChange="onDateChange"></date_input>
          </div>
          <div style="flex: 0 0 140px; margin-right: 16px">
            <string_input v-model="number" label="Номер: " :disabled="true"></string_input>
          </div>
          <div style="flex: 0 0 180px; margin-right: 16px">
            <date_input v-model="balance_date" label="Дата залишків: " :time="true" @userChange="onDateChange"></date_input>
          </div>
          <div style="flex: 0 0 300px; margin-right: 16px">
            <select_input
                v-model="operation"
                :import-getter="{ name: 'entering_balances_operation' }"
                :input-value-as-value="false"
                clearable
                label="Операція: "
                @selectChange="onOperationChange"
            />
          </div>
          <div style="flex: 0 0 100px; margin-right: 16px">
            <select_input
                v-model="currency_id"
                :computed-action="'SELECT_CURRENCY_LIST_ELEMENTS'"
                :computed-getter="'get_currency_list_select'"
                :input-value-as-value="false"
                :item-search="'text'"
                :show-on-focus="false"
                label="Валюта: "
                :select-button-object="{
                                name: 'CURRENCY_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список валют',
                                element_title: 'Валюта',
                                show: true
                              }"
                @selectChange="onDateChange"
            />
          </div>
        </div>
      </div>
      <div class="document-body pt-0 pb-1" style="flex: 1; display: flex; flex-direction: column; overflow: hidden;">
        <div class="px-4 pt-2 pb-0" style="flex: 1; display: flex; height: 100%;">
          <v-row style="height: 100%;">
            <v-col cols="12" sm="12" md="12" class="grey lighten-3 pb-0" style="overflow: hidden; height: 100%;">
              <v-tabs class="custom-tabs without" color="success" style="height: 100%; ">
                <v-tab>
                  Залишки
                </v-tab>

                <v-tab-item class="pa-0" style="height: 100%;">
                  <div class="command-panel mt-2">
                    <v-btn small
                           :dark="document_state.header_ready"
                           class="success mr-2" :disabled="!document_state.header_ready"
                           @click.stop="addTableRow"
                    >
                      <v-icon small left>mdi-plus</v-icon>
                      Додати рядок
                    </v-btn>
                    <v-btn small
                           :dark="document_state.header_ready"
                           class="error lighten-1" :disabled="!document_state.header_ready"
                           @click.stop="clearDialog"
                    >
                      <v-icon small left>mdi-close</v-icon>
                      Очистити таблицю
                    </v-btn>
                  </div>
                  <template v-if="operation === 'materials_goods_low_cost_in_warehouse'">
                    <div class="document-table with-command mt-2">
                      <div class="document-table-body">
                        <div class="document-table-header">
                          <div class="document-table-row">
                            <div class="document-table-col" style="flex: 0 0 70px">
                              № п/п
                            </div>
                            <div class="document-table-col" style="flex: 0 0 270px">
                              Номенклатура
                            </div>
                            <div class="document-table-col" style="flex: 0 0 100px">
                              Од. виміру
                            </div>
                            <div class="document-table-col" style="flex: 0 0 200px">
                              Склад
                            </div>
                            <div class="document-table-col" style="flex: 0 0 150px">
                              Рахунок б/о
                            </div>
                            <div class="document-table-col" style="flex: 0 0 120px">
                              Кількість
                            </div>
                            <div class="document-table-col" style="flex: 0 0 120px">
                              Сума
                            </div>
                          </div>
                      </div>
                        <div class="document-table-row"
                             v-for="(charge) in table"
                             :key="`charge-${charge.row_num}`"
                        >
                          <div class="document-table-col" style="flex: 0 0 70px; display: flex">
                            <div style="flex: 1; padding-left: 4px">
                              {{ table.indexOf(charge) + 1 }}
                            </div>
                            <div style="flex:  0 0 32px">
                              <v-btn icon small class="grey lighten-3" @click="deleteTableRow(charge)">
                                <v-icon small color="grey darken-2">mdi-delete</v-icon>
                              </v-btn>
                            </div>
                          </div>
                          <div class="document-table-col" style="flex: 0 0 270px">
                            <select_input
                                v-model="charge.nomenclature_id"
                                :computed-action="'SELECT_NOMENCLATURE_LIST_ELEMENTS'"
                                :computed-getter="'get_nomenclature_list_select'"
                                :input-value-as-value="false"
                                :item-search="'text'"
                                :show-on-focus="false"
                                :row_num="charge.row_num"
                                :show-select-on-focus="false"
                                :show-search-on-focus="true"
                                :action-props="{is_service: false}"
                                small
                                transparent
                                :select-button-object="{
                                name: 'NOMENCLATURE_ELEMENTS',
                                group: 'NOMENCLATURE_GROUP',
                                hierarchy: true,
                                only_groups: false,
                                title: 'Список номенклатури',
                                element_title: 'Номенклатура',
                                action_props: {is_service: false},
                                show: true
                              }"
                                :required="true"
                                @selectChange="afterNomenclatureSelect"
                            />
                          </div>
                          <div class="document-table-col" style="flex: 0 0 100px">
                            <select_input
                                v-model="charge.unit_type_id"
                                :computed-action="'SELECT_UNIT_LIST_ELEMENTS'"
                                :computed-getter="'get_unit_list_select'"
                                :input-value-as-value="false"
                                :item-search="'text'"
                                :item-text="'additional_field_1'"
                                :show-on-focus="false"
                                :row_num="charge.row_num"
                                :show-select-on-focus="false"
                                :show-search-on-focus="true"
                                small
                                transparent
                                :select-button-object="{
                                name: 'UNIT_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список одиниць виміру',
                                element_title: 'Одиниця виміру',
                                show: true
                              }"
                                :required="true"
                                @selectChange="tableColChange"
                            />
                          </div>
                          <div class="document-table-col" style="flex: 0 0 200px">
                            <select_input
                                v-model="charge.warehouse_id"
                                :computed-action="'SELECT_WAREHOUSE_LIST_ELEMENTS'"
                                :computed-getter="'get_warehouse_list_select'"
                                :input-value-as-value="false"
                                :item-search="'text'"
                                :show-on-focus="false"
                                :row_num="charge.row_num"
                                :show-select-on-focus="false"
                                :show-search-on-focus="true"
                                small
                                transparent
                                :select-button-object="{
                                  name: 'WAREHOUSE_ELEMENTS',
                                  group: '',
                                  hierarchy: false,
                                  only_groups: false,
                                  title: 'Список складів',
                                  element_title: 'Склад',
                                  show: true
                                }"
                                :required="true"
                                @selectChange="tableColChange"
                            />
                          </div>
                          <div class="document-table-col" style="flex: 0 0 150px">
                            <select_input
                                v-model="charge.chart_of_account_id"
                                :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                                :computed-getter="'get_chart_of_accounts_list_select'"
                                :input-value-as-value="false"
                                :item-search="'text'"
                                :show-on-focus="false"
                                :row_num="charge.row_num"
                                :show-select-on-focus="false"
                                :show-search-on-focus="true"
                                :action-filter="'@field=type@;@start-with=S@20@@@;@field=type@;@start-with=S@221@@@;@field=type@;@start-with=S@26@@@;@field=type@;@start-with=S@281'"
                                small
                                transparent
                                :select-button-object="{
                                name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'План рахунків',
                                element_title: 'Рахунок',
                                action_filter: '@field=code@;@start-with=S@20@@@;@field=code@;@start-with=S@221@@@;@field=code@;@start-with=S@26@@@;@field=code@;@start-with=S@281',
                                show: true
                              }"
                                :required="true"
                                @selectChange="tableColChange"
                            />
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px">
                            <number_input :row_num="charge.row_num" v-model="charge.count" v-decimal transparent
                                          small field="count"
                                          :required="true"
                                          @userChange="tableColChange"
                            />
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px">
                            <number_input :row_num="charge.row_num" v-model="charge.debit" v-decimal transparent
                                          small field="sum"
                                          :required="true"
                                          @userChange="tableColChange"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-if="operation === 'provider_and_customer'">
                    <div class="document-table with-command mt-2">
                      <div class="document-table-body">
                        <div class="document-table-header">
                          <div class="document-table-row">
                            <div class="document-table-col" style="flex: 0 0 70px">
                              № п/п
                            </div>
                            <div class="document-table-col" style="flex: 0 0 250px">
                              Рахунок розрахунків
                            </div>
                            <div class="document-table-col" style="flex: 0 0 240px">
                              Контрагент
                            </div>
                            <div class="document-table-col" style="flex: 0 0 180px">
                              Договір
                            </div>
                            <div class="document-table-col" style="flex: 0 0 120px">
                              Сальдо <br>
                              по дебету
                            </div>
                            <div class="document-table-col" style="flex: 0 0 120px">
                              Сальдо <br>
                              по кредиту
                            </div>
                          </div>
                        </div>
                        <div class="document-table-row"
                             v-for="(charge) in table"
                             :key="`charge-${charge.row_num}`"
                        >
                          <div class="document-table-col" style="flex: 0 0 70px; display: flex">
                            <div style="flex: 1; padding-left: 4px">
                              {{ table.indexOf(charge) + 1 }}
                            </div>
                            <div style="flex:  0 0 32px">
                              <v-btn icon small class="grey lighten-3" @click="deleteTableRow(charge)">
                                <v-icon small color="grey darken-2">mdi-delete</v-icon>
                              </v-btn>
                            </div>
                          </div>
                          <div class="document-table-col" style="flex: 0 0 250px">
                            <select_input
                                v-model="charge.chart_of_account_id"
                                :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                                :computed-getter="'get_chart_of_accounts_list_select'"
                                :input-value-as-value="false"
                                :item-search="'text'"
                                :show-on-focus="false"
                                :row_num="charge.row_num"
                                :show-select-on-focus="false"
                                :show-search-on-focus="true"
                                :action-filter="'@field=type@;@start-with=S@36@@@;@field=type@;@start-with=S@377@@@;@field=type@;@start-with=S@63@@@;@field=type@;@start-with=S@68'"
                                small
                                transparent
                                :select-button-object="{
                                name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'План рахунків',
                                element_title: 'Рахунок',
                                show: true,
                                action_filter: '@field=code@;@start-with=S@36@@@;@field=code@;@start-with=S@377@@@;@field=code@;@start-with=S@63@@@;@field=code@;@start-with=S@68',
                              }"
                                :required="true"
                            />
                          </div>
                          <div class="document-table-col" style="flex: 0 0 240px">
                            <select_input
                                v-model="charge.contractor_id"
                                :computed-action="'SELECT_CONTRACTOR_LIST_ELEMENTS'"
                                :computed-getter="'get_contractor_list_select'"
                                :input-value-as-value="false"
                                :item-search="'text'"
                                :show-on-focus="false"
                                :action-props="{date: date}"
                                :show-additional-field="true"
                                :row_num="0"
                                transparent
                                small
                                :show-select-on-focus="false"
                                :select-button-object="{
                                name: 'CONTRACTOR_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список контрагентів',
                                element_title: 'Контрагент',
                                show: true,
                                action_props: {date: date},
                                showAdditionalField: true
                              }"
                                clearable
                            />
                          </div>
                          <div class="document-table-col" style="flex: 0 0 180px">
                            <select_input
                                v-model="charge.contract_id"
                                :computed-action="'SELECT_CONTRACTOR_CONTRACT_LIST_ELEMENTS'"
                                :computed-getter="'get_contractor_contract_list_select'"
                                :input-value-as-value="false"
                                :item-search="'text'"
                                :show-on-focus="false"
                                :row_num="charge.row_num"
                                :owner_id="charge.contractor_id"
                                transparent
                                small
                                :action-props="{owner_id: charge.contractor_id}"
                                :show-select-on-focus="false"
                                :sync-action-props="true"
                                :select-button-object="{
                                name: 'CONTRACTOR_CONTRACT_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список договорів контрагента',
                                element_title: 'Договір контрагента',
                                show: true,
                                action_props: {owner_id: charge.contractor_id},
                                sync_action_props: true
                              }"
                            />
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px">
                            <number_input :row_num="charge.row_num" v-model="charge.debit" v-decimal transparent
                                          small
                                          :required="false"
                            />
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px">
                            <number_input :row_num="charge.row_num" v-model="charge.credit" v-decimal transparent
                                          small
                                          :required="false"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-if="operation === 'bank_operation'">
                    <div class="document-table with-command mt-2">
                      <div class="document-table-body">
                        <div class="document-table-header">
                          <div class="document-table-row">
                            <div class="document-table-col" style="flex: 0 0 70px">
                              № п/п
                            </div>
                            <div class="document-table-col" style="flex: 0 0 250px">
                              Рахунок 311-313
                            </div>
                            <div class="document-table-col" style="flex: 0 0 250px">
                              Банківський рахунок
                            </div>
                            <div class="document-table-col" style="flex: 0 0 250px">
                              Стаття руху грошових коштів
                            </div>
                            <div class="document-table-col" style="flex: 0 0 120px">
                              Сальдо
                            </div>
                          </div>
                        </div>
                        <div class="document-table-row"
                             v-for="(charge) in table"
                             :key="`charge-${charge.row_num}`"
                        >
                          <div class="document-table-col" style="flex: 0 0 70px; display: flex">
                            <div style="flex: 1; padding-left: 4px">
                              {{ table.indexOf(charge) + 1 }}
                            </div>
                            <div style="flex:  0 0 32px">
                              <v-btn icon small class="grey lighten-3" @click="deleteTableRow(charge)">
                                <v-icon small color="grey darken-2">mdi-delete</v-icon>
                              </v-btn>
                            </div>
                          </div>
                          <div class="document-table-col" style="flex: 0 0 250px">
                            <select_input
                                v-model="charge.chart_of_account_id"
                                :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                                :computed-getter="'get_chart_of_accounts_list_select'"
                                :input-value-as-value="false"
                                :item-search="'text'"
                                :show-on-focus="false"
                                :row_num="charge.row_num"
                                :show-select-on-focus="false"
                                :show-search-on-focus="true"
                                :action-filter="'@field=type@;@start-with=S@31'"
                                small
                                transparent
                                :select-button-object="{
                                name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'План рахунків',
                                element_title: 'Рахунок',
                                show: true,
                                action_filter: '@field=code@;@start-with=S@31',
                              }"
                                :required="true"
                            />
                          </div>
                          <div class="document-table-col" style="flex: 0 0 250px">
                            <select_input
                                v-model="charge.bank_account_id"
                                :computed-action="'SELECT_BANK_ACCOUNT_LIST_ELEMENTS'"
                                :computed-getter="'get_bank_account_list_select'"
                                :input-value-as-value="false"
                                :item-search="'text'"
                                :show-on-focus="false"
                                small
                                transparent
                                :row_num="charge.row_num"
                                :select-button-object="{
                                name: 'BANK_ACCOUNT_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список банківських рахунків',
                                element_title: 'Банківський рахунок',
                                show: true
                              }"
                            />
                          </div>
                          <div class="document-table-col" style="flex: 0 0 250px">
                            <select_input
                                v-model="charge.cash_flow_item_id"
                                :computed-action="'SELECT_CASH_FLOW_ITEM_LIST_ELEMENTS'"
                                :computed-getter="'get_cash_flow_item_list_select'"
                                :input-value-as-value="false"
                                :item-search="'text'"
                                :show-on-focus="false"
                                small
                                transparent
                                :row_num="charge.row_num"
                                :select-button-object="{
                                name: 'CASH_FLOW_ITEM_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список статей руху грошових коштів',
                                element_title: 'Стаття руху грошових коштів',
                                show: true
                              }"
                            />
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px">
                            <number_input :row_num="charge.row_num" v-model="charge.debit" v-decimal transparent
                                          small
                                          :required="false"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-if="operation === 'cash_operation'">
                    <div class="document-table with-command mt-2">
                      <div class="document-table-body">
                        <div class="document-table-header">
                          <div class="document-table-row">
                            <div class="document-table-col" style="flex: 0 0 70px">
                              № п/п
                            </div>
                            <div class="document-table-col" style="flex: 0 0 250px">
                              Рахунок 301-302
                            </div>
                            <div class="document-table-col" style="flex: 0 0 250px">
                              Стаття руху грошових коштів
                            </div>
                            <div class="document-table-col" style="flex: 0 0 120px">
                              Сальдо
                            </div>
                          </div>
                        </div>
                        <div class="document-table-row"
                             v-for="(charge) in table"
                             :key="`charge-${charge.row_num}`"
                        >
                          <div class="document-table-col" style="flex: 0 0 70px; display: flex">
                            <div style="flex: 1; padding-left: 4px">
                              {{ table.indexOf(charge) + 1 }}
                            </div>
                            <div style="flex:  0 0 32px">
                              <v-btn icon small class="grey lighten-3" @click="deleteTableRow(charge)">
                                <v-icon small color="grey darken-2">mdi-delete</v-icon>
                              </v-btn>
                            </div>
                          </div>
                          <div class="document-table-col" style="flex: 0 0 250px">
                            <select_input
                                v-model="charge.chart_of_account_id"
                                :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                                :computed-getter="'get_chart_of_accounts_list_select'"
                                :input-value-as-value="false"
                                :item-search="'text'"
                                :show-on-focus="false"
                                :row_num="charge.row_num"
                                :show-select-on-focus="false"
                                :show-search-on-focus="true"
                                :action-filter="'@field=type@;@start-with=S@30'"
                                small
                                transparent
                                :select-button-object="{
                                name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'План рахунків',
                                element_title: 'Рахунок',
                                show: true,
                                action_filter: '@field=code@;@start-with=S@30',
                              }"
                                :required="true"
                            />
                          </div>
                          <div class="document-table-col" style="flex: 0 0 250px">
                            <select_input
                                v-model="charge.cash_flow_item_id"
                                :computed-action="'SELECT_CASH_FLOW_ITEM_LIST_ELEMENTS'"
                                :computed-getter="'get_cash_flow_item_list_select'"
                                :input-value-as-value="false"
                                :item-search="'text'"
                                :show-on-focus="false"
                                small
                                transparent
                                :row_num="charge.row_num"
                                :select-button-object="{
                                name: 'CASH_FLOW_ITEM_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список статей руху грошових коштів',
                                element_title: 'Стаття руху грошових коштів',
                                show: true
                              }"
                            />
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px">
                            <number_input :row_num="charge.row_num" v-model="charge.debit" v-decimal transparent
                                          small
                                          :required="false"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-if="operation === 'fixed_and_low_cost_invest'">
                    <div class="document-table with-command mt-2">
                      <div class="document-table-body">
                        <div class="document-table-header">
                          <div class="document-table-row">
                            <div class="document-table-col" style="flex: 0 0 70px">
                              № п/п
                            </div>
                            <div class="document-table-col" style="flex: 0 0 270px">
                              Номенклатура
                            </div>
                            <div class="document-table-col" style="flex: 0 0 100px">
                              Од. виміру
                            </div>
                            <div class="document-table-col" style="flex: 0 0 200px">
                              Склад
                            </div>
                            <div class="document-table-col" style="flex: 0 0 150px">
                              Рахунок б/о
                            </div>
                            <div class="document-table-col" style="flex: 0 0 120px">
                              Кількість
                            </div>
                            <div class="document-table-col" style="flex: 0 0 120px">
                              Сума
                            </div>
                          </div>
                        </div>
                        <div class="document-table-row"
                             v-for="(charge) in table"
                             :key="`charge-${charge.row_num}`"
                        >
                          <div class="document-table-col" style="flex: 0 0 70px; display: flex">
                            <div style="flex: 1; padding-left: 4px">
                              {{ table.indexOf(charge) + 1 }}
                            </div>
                            <div style="flex:  0 0 32px">
                              <v-btn icon small class="grey lighten-3" @click="deleteTableRow(charge)">
                                <v-icon small color="grey darken-2">mdi-delete</v-icon>
                              </v-btn>
                            </div>
                          </div>
                          <div class="document-table-col" style="flex: 0 0 270px">
                            <select_input
                                v-model="charge.nomenclature_id"
                                :computed-action="'SELECT_NOMENCLATURE_LIST_ELEMENTS'"
                                :computed-getter="'get_nomenclature_list_select'"
                                :input-value-as-value="false"
                                :item-search="'text'"
                                :show-on-focus="false"
                                :row_num="charge.row_num"
                                :show-select-on-focus="false"
                                :show-search-on-focus="true"
                                :action-props="{is_service: false}"
                                small
                                transparent
                                :select-button-object="{
                                name: 'NOMENCLATURE_ELEMENTS',
                                group: 'NOMENCLATURE_GROUP',
                                hierarchy: true,
                                only_groups: false,
                                title: 'Список номенклатури',
                                element_title: 'Номенклатура',
                                action_props: {is_service: false},
                                show: true
                              }"
                                :required="true"
                                @selectChange="afterNomenclatureSelect"
                            />
                          </div>
                          <div class="document-table-col" style="flex: 0 0 100px">
                            <select_input
                                v-model="charge.unit_type_id"
                                :computed-action="'SELECT_UNIT_LIST_ELEMENTS'"
                                :computed-getter="'get_unit_list_select'"
                                :input-value-as-value="false"
                                :item-search="'text'"
                                :item-text="'additional_field_1'"
                                :show-on-focus="false"
                                :row_num="charge.row_num"
                                :show-select-on-focus="false"
                                :show-search-on-focus="true"
                                small
                                transparent
                                :select-button-object="{
                                name: 'UNIT_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список одиниць виміру',
                                element_title: 'Одиниця виміру',
                                show: true
                              }"
                                :required="true"
                                @selectChange="tableColChange"
                            />
                          </div>
                          <div class="document-table-col" style="flex: 0 0 200px">
                            <select_input
                                v-model="charge.warehouse_id"
                                :computed-action="'SELECT_WAREHOUSE_LIST_ELEMENTS'"
                                :computed-getter="'get_warehouse_list_select'"
                                :input-value-as-value="false"
                                :item-search="'text'"
                                :show-on-focus="false"
                                :row_num="charge.row_num"
                                :show-select-on-focus="false"
                                :show-search-on-focus="true"
                                small
                                transparent
                                :select-button-object="{
                                  name: 'WAREHOUSE_ELEMENTS',
                                  group: '',
                                  hierarchy: false,
                                  only_groups: false,
                                  title: 'Список складів',
                                  element_title: 'Склад',
                                  show: true
                                }"
                                :required="true"
                                @selectChange="tableColChange"
                            />
                          </div>
                          <div class="document-table-col" style="flex: 0 0 150px">
                            <select_input
                                v-model="charge.chart_of_account_id"
                                :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                                :computed-getter="'get_chart_of_accounts_list_select'"
                                :input-value-as-value="false"
                                :item-search="'text'"
                                :show-on-focus="false"
                                :row_num="charge.row_num"
                                :show-select-on-focus="false"
                                :show-search-on-focus="true"
                                :action-filter="'@field=type@;@start-with=S@1521@@@;@field=type@;@start-with=S@1531'"
                                small
                                transparent
                                :select-button-object="{
                                name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'План рахунків',
                                element_title: 'Рахунок',
                                action_filter: '@field=code@;@start-with=S@1521@@@;@field=code@;@start-with=S@1531',
                                show: true
                              }"
                                :required="true"
                                @selectChange="tableColChange"
                            />
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px">
                            <number_input :row_num="charge.row_num" v-model="charge.count" v-decimal transparent
                                          small field="count"
                                          :required="true"
                                          @userChange="tableColChange"
                            />
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px">
                            <number_input :row_num="charge.row_num" v-model="charge.debit" v-decimal transparent
                                          small field="sum"
                                          :required="true"
                                          @userChange="tableColChange"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-if="operation === 'other'">
                    <div class="document-table with-command mt-2">
                      <div class="document-table-body">
                        <div class="document-table-header">
                          <div class="document-table-row">
                            <div class="document-table-col" style="flex: 0 0 70px">
                              № п/п
                            </div>
                            <div class="document-table-col" style="flex: 0 0 250px">
                              Рахунок
                            </div>
                            <div class="document-table-col" style="flex: 0 0 250px">
                              Субконто 1
                            </div>
                            <div class="document-table-col" style="flex: 0 0 250px">
                              Субконто 2
                            </div>
                            <div class="document-table-col" style="flex: 0 0 250px">
                              Субконто 3
                            </div>
                            <div class="document-table-col" style="flex: 0 0 120px">
                              Сальдо <br> по дебету
                            </div>
                            <div class="document-table-col" style="flex: 0 0 120px">
                              Сальдо <br> по кредиту
                            </div>
                          </div>
                        </div>
                        <div class="document-table-row"
                             v-for="(charge) in table"
                             :key="`charge-${charge.row_num}`"
                        >
                          <div class="document-table-col" style="flex: 0 0 70px; display: flex">
                            <div style="flex: 1; padding-left: 4px">
                              {{ table.indexOf(charge) + 1 }}
                            </div>
                            <div style="flex:  0 0 32px">
                              <v-btn icon small class="grey lighten-3" @click="deleteTableRow(charge)">
                                <v-icon small color="grey darken-2">mdi-delete</v-icon>
                              </v-btn>
                            </div>
                          </div>
                          <div class="document-table-col" style="flex: 0 0 250px">
                            <select_input
                                v-model="charge.chart_of_account_id"
                                :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                                :computed-getter="'get_chart_of_accounts_list_select'"
                                :input-value-as-value="false"
                                :item-search="'text'"
                                :show-on-focus="false"
                                :row_num="charge.row_num"
                                :show-select-on-focus="false"
                                :show-search-on-focus="true"
                                small
                                transparent
                                :select-button-object="{
                              name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                              group: '',
                              hierarchy: false,
                              only_groups: false,
                              title: 'План рахунків',
                              element_title: 'Рахунок',
                              show: true,
                            }"
                                :required="true"
                                @selectChange="afterChartAccountChange"
                            />
                          </div>
                          <div class="document-table-col" style="flex: 0 0 250px">
                            <template v-if="charge.account_detail_1_type">
                              <select_input
                                  v-model="charge.account_detail_1_value"
                                  :computed-action="subconto_type_select_setting[charge.account_detail_1_type].computed_action"
                                  :computed-getter="subconto_type_select_setting[charge.account_detail_1_type].computed_getter"
                                  :input-value-as-value="false"
                                  :item-search="'text'"
                                  :show-on-focus="false"
                                  :row_num="charge.row_num"
                                  :show-select-on-focus="false"
                                  small
                                  transparent
                                  :required="true"
                                  :select-button-object="{
                                  name: subconto_type_select_setting[charge.account_detail_1_type].select_button_object.name,
                                  group: subconto_type_select_setting[charge.account_detail_1_type].select_button_object.group,
                                  hierarchy: subconto_type_select_setting[charge.account_detail_1_type].select_button_object.hierarchy,
                                  only_groups: subconto_type_select_setting[charge.account_detail_1_type].select_button_object.only_groups,
                                  title: subconto_type_select_setting[charge.account_detail_1_type].select_button_object.title,
                                  element_title: subconto_type_select_setting[charge.account_detail_1_type].select_button_object.element_title,
                                  show: true
                                }"/>
                            </template>
                          </div>
                          <div class="document-table-col" style="flex: 0 0 250px">
                            <template v-if="charge.account_detail_2_type">
                              <select_input
                                  v-if="charge.account_detail_2_type"
                                  v-model="charge.account_detail_2_value"
                                  :computed-action="subconto_type_select_setting[charge.account_detail_2_type].computed_action"
                                  :computed-getter="subconto_type_select_setting[charge.account_detail_2_type].computed_getter"
                                  :input-value-as-value="false"
                                  :item-search="'text'"
                                  :show-on-focus="false"
                                  :row_num="charge.row_num"
                                  :show-select-on-focus="false"
                                  small
                                  transparent
                                  :required="true"
                                  :select-button-object="{
                                  name: subconto_type_select_setting[charge.account_detail_2_type].select_button_object.name,
                                  group: subconto_type_select_setting[charge.account_detail_2_type].select_button_object.group,
                                  hierarchy: subconto_type_select_setting[charge.account_detail_2_type].select_button_object.hierarchy,
                                  only_groups: subconto_type_select_setting[charge.account_detail_2_type].select_button_object.only_groups,
                                  title: subconto_type_select_setting[charge.account_detail_2_type].select_button_object.title,
                                  element_title: subconto_type_select_setting[charge.account_detail_2_type].select_button_object.element_title,
                                  show: true
                                }"/>
                            </template>
                          </div>
                          <div class="document-table-col" style="flex: 0 0 250px">
                            <template v-if="charge.account_detail_3_type">
                              <select_input
                                  v-if="charge.account_detail_3_type"
                                  v-model="charge.account_detail_3_value"
                                  :computed-action="subconto_type_select_setting[charge.account_detail_3_type].computed_action"
                                  :computed-getter="subconto_type_select_setting[charge.account_detail_3_type].computed_getter"
                                  :input-value-as-value="false"
                                  :item-search="'text'"
                                  :show-on-focus="false"
                                  :row_num="charge.row_num"
                                  :show-select-on-focus="false"
                                  small
                                  transparent
                                  :required="true"
                                  :select-button-object="{
                                  name: subconto_type_select_setting[charge.account_detail_3_type].select_button_object.name,
                                  group: subconto_type_select_setting[charge.account_detail_3_type].select_button_object.group,
                                  hierarchy: subconto_type_select_setting[charge.account_detail_3_type].select_button_object.hierarchy,
                                  only_groups: subconto_type_select_setting[charge.account_detail_3_type].select_button_object.only_groups,
                                  title: subconto_type_select_setting[charge.account_detail_3_type].select_button_object.title,
                                  element_title: subconto_type_select_setting[charge.account_detail_3_type].select_button_object.element_title,
                                  show: true
                                }"/>
                            </template>
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px">
                            <number_input :row_num="charge.row_num" v-model="charge.debit" v-decimal transparent
                                          small
                                          :required="false"
                            />
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px">
                            <number_input :row_num="charge.row_num" v-model="charge.credit" v-decimal transparent
                                          small
                                          :required="false"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-if="operation === 'salary'">
                    <div class="document-table with-command mt-2">
                      <div class="document-table-body">
                        <div class="document-table-header">
                          <div class="document-table-row">
                            <div class="document-table-col" style="flex: 0 0 70px">
                              № п/п
                            </div>
                            <div class="document-table-col" style="flex: 0 0 200px">
                              Рахунок
                            </div>
                            <div class="document-table-col" style="flex: 0 0 230px">
                              Працівник
                            </div>
                            <div class="document-table-col" style="flex: 0 0 180px">
                              Група нарахування
                            </div>
                            <div class="document-table-col" style="flex: 0 0 120px">
                              Сальдо <br> по дебету
                            </div>
                            <div class="document-table-col" style="flex: 0 0 120px">
                              Сальдо <br> по кредиту
                            </div>
                          </div>
                        </div>
                        <div class="document-table-row"
                             v-for="(charge) in table"
                             :key="`charge-${charge.row_num}`"
                        >
                          <div class="document-table-col" style="flex: 0 0 70px; display: flex">
                            <div style="flex: 1; padding-left: 4px">
                              {{ table.indexOf(charge) + 1 }}
                            </div>
                            <div style="flex:  0 0 32px">
                              <v-btn icon small class="grey lighten-3" @click="deleteTableRow(charge)">
                                <v-icon small color="grey darken-2">mdi-delete</v-icon>
                              </v-btn>
                            </div>
                          </div>
                          <div class="document-table-col" style="flex: 0 0 200px">
                            <select_input
                                v-model="charge.chart_of_account_id"
                                :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                                :computed-getter="'get_chart_of_accounts_list_select'"
                                :input-value-as-value="false"
                                :item-search="'text'"
                                :show-on-focus="false"
                                :row_num="charge.row_num"
                                :show-select-on-focus="false"
                                :show-search-on-focus="true"
                                :action-filter="'@field=type@;@start-with=S@661@@@;@field=type@;@start-with=S@663'"
                                small
                                transparent
                                :select-button-object="{
                                name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'План рахунків',
                                element_title: 'Рахунок',
                                show: true,
                                action_filter: '@field=code@;@start-with=S@661@@@;@field=code@;@start-with=S@663',
                              }"
                                :required="true"
                                @selectChange="tableColChange"
                            />
                          </div>
                          <div class="document-table-col" style="flex: 0 0 230px">
                            <select_input
                                v-model="charge.person_id"
                                :computed-action="'SELECT_PERSONAL_LIST_ELEMENTS'"
                                :computed-getter="'get_personal_list_select'"
                                :input-value-as-value="false"
                                :item-search="'text'"
                                :show-on-focus="false"
                                :row_num="charge.row_num"
                                :show-select-on-focus="false"
                                :required="true"
                                :disabled="!!charge.person_id"
                                transparent
                                :select-button-object="{
                                                        name: 'PERSONAL_ELEMENTS',
                                                        group: '',
                                                        hierarchy: false,
                                                        only_groups: false,
                                                        title: 'Список працівників',
                                                        element_title: 'Працівник',
                                                        show: true
                                                      }"
                                small
                                @selectChange="tableColChange"
                            />
                          </div>
                          <div class="document-table-col" style="flex: 0 0 180px">
                            <select_input
                                v-model="charge.accrual_group"
                                :import-getter="{ name: 'accrual_group_select' }"
                                :input-value-as-value="false"
                                :row_num="charge.row_num"
                                small
                                transparent
                                @selectChange="tableColChange"
                            />
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px">
                            <number_input :row_num="charge.row_num" v-model="charge.debit" v-decimal transparent
                                          small
                                          :required="false"
                            />
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px">
                            <number_input :row_num="charge.row_num" v-model="charge.credit" v-decimal transparent
                                          small
                                          :required="false"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </v-tab-item>
              </v-tabs>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card-text>
    <v-card-actions style="flex: 0">
      <v-spacer></v-spacer>
      <v-btn depressed color="grey lighten-3" v-if="!conducted" :loading="getModalLoading" :disabled="isNew"
             @click="doConducted(true)">
        <v-icon color="success">mdi-file-check-outline</v-icon>
      </v-btn>
      <v-btn depressed color="grey lighten-3" v-if="conducted" :loading="getModalLoading" :disabled="isNew"
             @click="doConducted(false)">
        <v-icon color="error lighten-1">mdi-file-cancel-outline</v-icon>
      </v-btn>
      <v-btn depressed text color="secondary darken-1" :loading="getModalLoading"
             @click="crud_item" class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Провести та закрити
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  CREATE_DOCUMENT_ENTERING_BALANCES_ELEMENTS,
  UPDATE_DOCUMENT_ENTERING_BALANCES_ELEMENTS,
  REMOVE_DOCUMENT_ENTERING_BALANCES_ELEMENTS,
  CONDUCTED_DOCUMENT_ENTERING_BALANCES_ELEMENTS,
} from "@/store/actions/accounting/list";
import {mapGetters} from 'vuex'
import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import enteringBalanceAPI from "@/utils/axios/accounting/documents/entering_balance";
import {maxBy} from "lodash";
import {subconto_type_select_setting} from "@/utils/accounting";
import chartOfAccountAPI from "@/utils/axios/accounting/chart_of_account";

import ModalAccountingComponentMixin from "@/mixins/modal_account_component";

const modalDeleteId = 'entering_balances_accounting_modal_delete'

export default {
  name: "HWP_Modal_Accounting_Document_EnteringBalances",
  mixins: [ModalAccountingComponentMixin],
  components: {
    date_input: () => import("@/components/accounting/input/document_date_input"),
    number_input: () => import("@/components/accounting/input/document_number_input"),
    string_input: () => import("@/components/accounting/input/document_string_input"),
    select_input: () => import("@/components/accounting/input/document_select_input")
  },
  data() {
    return {
      subconto_type_select_setting,
      balance_date: this.item.balance_date || null,
      operation: this.item.operation || null,
      currency_id: this.item.currency_id || null,
      table: [],
    }
  },
  methods: {
    checkDocumentTable(filters, table_name, table) {
      const table_charge_error = []
      const items = this[table].filter(item => {
        let r = false
        Object.keys(filters).forEach(key => {
          if (item[key] === undefined || item[key] === null || item[key] === 0) {
            r = true
          }
        })
        return r
      })

      items.forEach(item => {
        const idx = this[table].indexOf(item)
        const row_key = `row_${idx}`

        Object.keys(filters).forEach(key => {
          const filter_row = filters[key]
          let skip = false

          if (filter_row.condition) {
            if (item[filter_row.condition] !== null && item[filter_row.condition] !== undefined && item[filter_row.condition] !== 0) {
              skip = item[key] !== null && item[key] !== undefined && item[key] !== 0
            } else {
              skip = true
            }
          } else {
            skip = item[key] !== null && item[key] !== undefined && item[key] !== 0;
          }

          if (!skip) {
            if (!table_charge_error[row_key]) {
              table_charge_error[row_key] = {row_num: idx + 1, text: filters[key].text}
            } else {
              table_charge_error[row_key].text += `// ${filters[key].text}`
            }
          }
        })
      })

      Object.keys(table_charge_error).forEach(key => {
        this.document_state.errors.push(
            {table: table_name, row_num: table_charge_error[key].row_num, text: table_charge_error[key].text}
        )
      })
    },
    checkDocument(show=false, table=true) {
      this.document_state.show = false
      this.document_state.errors = []

      const header = {
        'date': {id: 'date', text: 'Не заповнена дата документа'},
        'balance_date': {id: 'balance_date', text: 'Не заповнена дата введення залишків'},
        'currency_id': {id: 'currency_id', text: 'Не заповнена валюта'},
        'operation': {id: 'operation', text: 'Не заповнений розділ обліку'}
      }

      Object.keys(header).forEach(key => {
        if (!this[key]) {
          this.document_state.errors.push(
              {table: 'Шапка', row_num: 0, text: header[key].text}
          )
        }
      })

      const balances_table = {
        'chart_of_account_id': {id: 'chart_of_account_id', table: 'Залишки', text: 'Не заповнений рахунок'},
      }

      if (this.operation === 'materials_goods_low_cost_in_warehouse') {
        balances_table['nomenclature_id'] = {id: 'nomenclature_id', table: 'Залишки', text: 'Не зповнена номенклатура'}
        balances_table['unit_type_id'] = {id: 'unit_type_id', table: 'Залишки', text: 'Не зповнена одиниця виміру'}
        balances_table['warehouse_id'] = {id: 'warehouse_id', table: 'Залишки', text: 'Не зповнений склад'}
        balances_table['count'] = {id: 'count', table: 'Залишки', text: 'Не зповнена кількість'}
        balances_table['debit'] = {id: 'debit', table: 'Залишки', text: 'Не зповнене сальдо'}
      }
      if (this.operation === 'provider_and_customer') {
        balances_table['contractor_id'] = {id: 'contractor_id', table: 'Залишки', text: 'Не зповнений контрагент'}
        balances_table['contract_id'] = {id: 'contract_id', table: 'Залишки', text: 'Не зповнений договір контрагента'}
      }
      if (this.operation === 'bank_operation') {
        balances_table['bank_account_id'] = {id: 'bank_account_id', table: 'Залишки', text: 'Не зповнений банківський рахунок'}
        balances_table['cash_flow_item_id'] = {id: 'cash_flow_item_id', table: 'Залишки', text: 'Не зповнена стаття руху грошових коштів'}
        balances_table['debit'] = {id: 'debit', table: 'Залишки', text: 'Не зповнене сальдо'}
      }
      if (this.operation === 'cash_operation') {
        balances_table['cash_flow_item_id'] = {id: 'cash_flow_item_id', table: 'Залишки', text: 'Не зповнена стаття руху грошових коштів'}
        balances_table['debit'] = {id: 'debit', table: 'Залишки', text: 'Не зповнене сальдо'}
      }
      if (this.operation === 'fixed_and_low_cost_invest') {
        balances_table['nomenclature_id'] = {id: 'nomenclature_id', table: 'Залишки', text: 'Не зповнена номенклатура'}
        balances_table['unit_type_id'] = {id: 'unit_type_id', table: 'Залишки', text: 'Не зповнена одиниця виміру'}
        balances_table['warehouse_id'] = {id: 'warehouse_id', table: 'Залишки', text: 'Не зповнений склад'}
        balances_table['count'] = {id: 'count', table: 'Залишки', text: 'Не зповнена кількість'}
        balances_table['debit'] = {id: 'debit', table: 'Залишки', text: 'Не зповнене сальдо'}
      }

      this.document_state.header_ready = this.document_state.errors.length === 0;

      if (table) {
        this.checkDocumentTable(balances_table, 'Залишки', 'table')
      }

      if (this.document_state.errors.length) {
        this.document_state.ready = false
        this.document_state.show = show
      } else {
        this.document_state.ready = true
        this.document_state.show = false
      }

    },
    doConducted(payload) {
      if (this.isNew) return

      this.$store.dispatch(CONDUCTED_DOCUMENT_ENTERING_BALANCES_ELEMENTS,
          {document_id: this.itemId, conducted: payload}
      )
          .then(data => {
            if (data) {
              const text = payload ? 'Документ успішно проведений' : 'Відміна проведення успішно виконана'
              const color = payload ? 'success' : 'error'
              this.conducted = payload
              this.$store.commit(ALERT_SHOW, {text, color})
            }

          })
    },
    getChartsOfAccounts() {
      chartOfAccountAPI.get_all()
          .then(response => response.data)
          .then(data => {
            this.charts = data
          })
    },

    clearPersonData(payload) {
      let local_data = payload || {}

      this.itemId = payload.id || null
      this.date = local_data.date || null
      this.balance_date = local_data.balance_date || null
      this.number = local_data.number || null
      this.operation = local_data.operation || null
      this.conducted = local_data.conducted || false
      this.currency_id = local_data.currency_id || null

      this.table = []
    },
    fetch_data_by_id() {
      this.getChartsOfAccounts()
      if (this.isNew) return

      enteringBalanceAPI.get_document_by_id(this.itemId)
          .then(response => response.data)
          .then(data => {
            this.date = data.date || null
            this.number = data.number || null
            this.conducted = data.conducted || false
            this.balance_date = data.balance_date || false
            this.operation = data.operation || null
            this.currency_id = data.currency_id || null
            this.table = data.table.map((i, idx) => {
              return Object.assign({}, i, {row_num: idx + 1})
            })
          })
    },

    onOperationChange() {
      // const operation = (payload || {}).value || null
      this.table.forEach(item => {
        item.chart_of_account_id = null
        item.account_detail_1_value = null
        item.account_detail_2_value = null
        item.account_detail_3_value = null
        item.account_detail_1_type = null
        item.account_detail_2_type = null
        item.account_detail_3_type = null
      })
      this.$nextTick(() => {
        this.checkDocument(false, false)
      })
    },
    onDateChange() {
      this.$nextTick(() => {
        this.checkDocument(false, false)
      })
    },
    tableColChange() {
      this.$nextTick(() => {
        this.checkDocument(false, true)
      })
    },
    get_account_details(chart_id) {
      const chart = this.charts.find(c => c.id === chart_id) || {}
      const details = {
        account_detail_1: {id: null, type: null},
        account_detail_2: {id: null, type: null},
        account_detail_3: {id: null, type: null},
      }
      if (chart.id) {
        const ch_details = chart.details || []

        const obj_1 = ch_details.find(i => i.order === 1)
        const obj_2 = ch_details.find(i => i.order === 2)
        const obj_3 = ch_details.find(i => i.order === 3)

        if (obj_1) {
          details.account_detail_1.type = obj_1.detail_type
          details.account_detail_1.id = obj_1.id
        }
        if (obj_2) {
          details.account_detail_2.type = obj_2.detail_type
          details.account_detail_2.id = obj_2.id
        }
        if (obj_3) {
          details.account_detail_3.type = obj_3.detail_type
          details.account_detail_3.id = obj_3.id
        }
      }
      return details
    },
    afterChartAccountChange(payload) {
      const row = this.table.find(item => item.row_num === payload.row_num)
      if (row) {
        let details = {}
        if ('text' in payload && 'value' in payload) {
          details = this.get_account_details(payload.value)
        } else {
          details = this.get_account_details(payload.id)
        }
        row.account_detail_1_type = details.account_detail_1.type
        row.account_detail_1_value = null
        row.account_detail_2_type = details.account_detail_2.type
        row.account_detail_2_value = null
        row.account_detail_3_type = details.account_detail_3.type
        row.account_detail_3_value = null
      }
      this.$nextTick(() => {
        this.checkDocument(false, true)
      })
    },
    afterNomenclatureSelect(payload) {
      const val = payload || {}
      let pdv, is_service, unit_type_id
      if ("value" in val) {
        pdv = +(val.type || '0')
        is_service = val.additional_field_1 === "True"
        unit_type_id = +(val.additional_field_2) || null
      } else {
        pdv = val.tax_type
        is_service = val.is_service
        unit_type_id = val.unit_type
      }

      if (val.row_num) {
        const row = this.table.find(item => item.row_num === val.row_num)
        if (row) {
          if (is_service) {
            this.$nextTick(() => {
              row.nomenclature_id = null
            })
            return;
          }
          row.tax_id = pdv
          row.unit_type_id = unit_type_id
          this.tableColChange(val)
        }
      }
    },
    deleteTableRow(payload) {
      this.table.splice(this.table.indexOf(payload), 1)
    },
    getNewRowNum(table) {
      const max_row_item = maxBy(this[table], 'row_num')

      let max_row_num = 1
      if (max_row_item) {
        max_row_num = max_row_item.row_num + 1
      }
      return max_row_num
    },
    addTableRow() {
      let warehouse_id = this.settings?.default_warehouse?.value || null
      let chart_of_account_id = null

      const chart_361 = this.charts.find(c => c.code === '361') || {}
      const chart_201 = this.charts.find(c => c.code === '201') || {}
      const chart_1521 = this.charts.find(c => c.code === '1521') || {}
      const chart_311 = this.charts.find(c => c.code === '311') || {}
      const chart_301 = this.charts.find(c => c.code === '301') || {}
      const chart_661 = this.charts.find(c => c.code === '661') || {}

      if (this.operation === 'materials_goods_low_cost_in_warehouse') {
        chart_of_account_id = chart_201.id || null
      }
      if (this.operation === 'provider_and_customer') {
        chart_of_account_id = chart_361.id || null
      }
      if (this.operation === 'bank_operation') {
        chart_of_account_id = chart_311.id || null
      }
      if (this.operation === 'cash_operation') {
        chart_of_account_id = chart_301.id || null
      }
      if (this.operation === 'fixed_and_low_cost_invest') {
        chart_of_account_id = chart_1521.id || null
      }
      if (this.operation === 'salary') {
        chart_of_account_id = chart_661.id || null
      }

        this.table.push(
            {
              nomenclature_id: null,
              unit_type_id: null,
              warehouse_id,
              chart_of_account_id,
              person_id: null,
              accrual_group: null,
              count: 1,
              debit: 0,
              credit: 0,
              contractor_id: null,
              contract_id: null,
              bank_account_id: null,
              cash_flow_item_id: null,
              account_detail_1_value: null,
              account_detail_2_value: null,
              account_detail_3_value: null,
              account_detail_1_type: null,
              account_detail_2_type: null,
              account_detail_3_type: null,
              row_num: this.getNewRowNum('table'),
            }
        )
    },
    clearDialog() {
      const payload = {
        text: `Очистити таблицю із залишками?`,
        accept_button: true,
        id: 'clear_table'
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },

    closeModal() {
      this.$emit('closeModal')
      this.itemId = this.item.id
      this.date = this.item.date || null
      this.number = this.item.number || null
      this.conducted = this.item.conducted || null
      this.balance_date = this.item.balance_date || false
      this.operation = this.item.operation || null
      this.currency_id = this.item.currency_id || null
      this.table = []

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення банківського рахунку: ${this.print_name}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    crud_item() {
      this.checkDocument()
      if (!this.document_state.ready) {
        this.document_state.show = true
        return
      }

      const payload = {
        date: this.date,
        balance_date: this.balance_date,
        table: this.table,
        operation: this.operation,
        currency_id: this.currency_id
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_DOCUMENT_ENTERING_BALANCES_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      } else {
        payload.number = this.number
        payload.id = this.itemId
        this.$store.dispatch(UPDATE_DOCUMENT_ENTERING_BALANCES_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      }
    },
    watch_item() {
      this.watcher_item = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.clearPersonData(payload)
              this.fetch_data_by_id()
              this.checkDocument()
            }
          }
      )
    },
    watch_dialog() {
      this.watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher_item) {
                  this.watcher_item()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(REMOVE_DOCUMENT_ENTERING_BALANCES_ELEMENTS, this.itemId)
                      .then(ok => {
                        if (ok) {
                          this.closeModal()
                        }
                      })
                }
              }
              if (payload.id === 'clear_table') {
                if (payload.answer) {
                  this.table = []
                }
              }
            }
          }
      )
    }
  },
  computed: {
    ...mapGetters({
      settings: 'getAccountingGeneralSettings'
    }),
  }
}
</script>
